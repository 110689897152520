import request from "@/utils/request";

function paramList(data) {
  return request({
    url: "/show/param/list",
    method: "post",
    data: data,
  });
}

function paramAdd(data) {
  return request({
    url: "/show/param/add",
    method: "post",
    data: data,
  });
}

function paramUpdate(data) {
  return request({
    url: "/show/param/update",
    method: "post",
    data: data,
  });
}

function paramDel(data) {
  return request({
    url: "/show/param/del",
    method: "post",
    data: data,
  });
}

function paramOne(data) {
  return request({
    url: "/show/param/one",
    method: "post",
    data: data,
  });
}

export { paramList, paramAdd, paramUpdate, paramDel, paramOne };
