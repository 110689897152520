import request from "@/utils/request";

function defineFilter(data) {
  return request({
    url: "/show/define/filter",
    method: "post",
    data: data,
  });
}

function interfaceList(data) {
  return request({
    url: "/show/interface/list",
    method: "post",
    data: data,
  });
}

function interfaceAdd(data) {
  return request({
    url: "/show/interface/add",
    method: "post",
    data: data,
  });
}

function interfaceUpdate(data) {
  return request({
    url: "/show/interface/update",
    method: "post",
    data: data,
  });
}

function interfaceDel(data) {
  return request({
    url: "/show/interface/del",
    method: "post",
    data: data,
  });
}

function interfaceOne(data) {
  return request({
    url: "/show/interface/one",
    method: "post",
    data: data,
  });
}

function interfaceTestGet(data) {
  return request({
    url: "/show/test/get",
    method: "post",
    data: data,
  });
}

function interfaceTestAdd(data) {
  return request({
    url: "/show/test/add",
    method: "post",
    data: data,
  });
}

function interfaceTestUpdate(data) {
  return request({
    url: "/show/test/update",
    method: "post",
    data: data,
  });
}

function interfaceMockGet(data) {
  return request({
    url: "/show/mock/get",
    method: "post",
    data: data,
  });
}

function interfaceMockAdd(data) {
  return request({
    url: "/show/mock/add",
    method: "post",
    data: data,
  });
}

function interfaceMockUpdate(data) {
  return request({
    url: "/show/mock/update",
    method: "post",
    data: data,
  });
}

function interfaceDocAdd(data) {
  return request({
    url: "/show/interfaceDoc/add",
    method: "post",
    data: data,
  });
}

function interfaceDocUpdate(data) {
  return request({
    url: "/show/interfaceDoc/update",
    method: "post",
    data: data,
  });
}

function interfaceDocDel(data) {
  return request({
    url: "/show/interfaceDoc/del",
    method: "post",
    data: data,
  });
}

export {
  defineFilter,
  interfaceList,
  interfaceAdd,
  interfaceUpdate,
  interfaceDel,
  interfaceOne,
  interfaceTestGet,
  interfaceTestAdd,
  interfaceTestUpdate,
  interfaceMockGet,
  interfaceMockAdd,
  interfaceMockUpdate,
  interfaceDocAdd,
  interfaceDocUpdate,
  interfaceDocDel,
};
