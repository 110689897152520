import request from "@/utils/request";

function mysqlTableList(data) {
  return request({
    url: "/show/mysql/table/list",
    method: "post",
    data: data,
  });
}

function mysqlTableAdd(data) {
  return request({
    url: "/show/mysql/table/add",
    method: "post",
    data: data,
  });
}

function mysqlTableRename(data) {
  return request({
    url: "/show/mysql/table/rename",
    method: "post",
    data: data,
  });
}

function mysqlTableRetip(data) {
  return request({
    url: "/show/mysql/table/retip",
    method: "post",
    data: data,
  });
}

function mysqlColList(data) {
  return request({
    url: "/show/mysql/col/list",
    method: "post",
    data: data,
  });
}

function mysqlColAdd(data) {
  return request({
    url: "/show/mysql/col/add",
    method: "post",
    data: data,
  });
}

function mysqlColDel(data) {
  return request({
    url: "/show/mysql/col/del",
    method: "post",
    data: data,
  });
}

function mysqlColUpdate(data) {
  return request({
    url: "/show/mysql/col/update",
    method: "post",
    data: data,
  });
}

export {
  mysqlTableList,
  mysqlTableAdd,
  mysqlTableRename,
  mysqlTableRetip,
  mysqlColList,
  mysqlColAdd,
  mysqlColDel,
  mysqlColUpdate,
};
