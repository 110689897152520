<template>
  <div>
    <PagingQueryPage
      ref="PagingQueryPage"
      :queryFormState="queryFormState"
      size="middle"
      :bordered="true"
      rowKey="interfaceId"
      :columns="columns"
      :pagination="pagination"
      dataUrl="/show/interface/list"
      :requestOptions="requestOptions"
    >
      <template #query>
        <a-form-model-item label="接口路径">
          <a-input v-model="queryFormState.interfaceUrl"></a-input>
        </a-form-model-item>
        <a-form-model-item label="模块" name="interfaceOfModule">
          <a-select v-model="queryFormState.interfaceOfModule">
            <a-select-option
              v-for="item in modules.moduleList"
              :key="item.id"
              :value="item.moduleId"
            >
              {{ item.moduleName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="服务" name="interfaceOfService">
          <a-select v-model="queryFormState.interfaceOfService">
            <a-select-option
              v-for="item in modules.serviceList"
              :key="item.id"
              :value="item.moduleId"
            >
              {{ item.moduleName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="页面" name="interfaceOfPage">
          <a-select v-model="queryFormState.interfaceOfPage">
            <a-select-option
              v-for="item in modules.pageList"
              :key="item.id"
              :value="item.moduleId"
            >
              {{ item.moduleName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="客户端" name="interfaceOfClient">
          <a-select v-model="queryFormState.interfaceOfClient">
            <a-select-option
              v-for="item in modules.clientList"
              :key="item.id"
              :value="item.moduleId"
            >
              {{ item.moduleName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="其他" name="interfaceOfOthers">
          <a-select v-model="queryFormState.interfaceOfOthers">
            <a-select-option
              v-for="item in modules.otherList"
              :key="item.id"
              :value="item.moduleId"
            >
              {{ item.moduleName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </template>
      <a-button type="primary" icon="plus" slot="action" @click="add">
        新增
      </a-button>
    </PagingQueryPage>
    <a-modal
      :width="1200"
      :visible="modalVisible"
      :title="(isAdd ? '新增' : '编辑') + modalTitle"
      ok-text="确认"
      cancel-text="取消"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="submit"
      @cancel="
        () => {
          modalVisible = false;
        }
      "
    >
      <a-form-model
        ref="modalForm"
        :model="modalFormState"
        :rules="modalRules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="路径" prop="interfaceUrl">
          <a-input v-model="modalFormState.interfaceUrl" :disabled="!isEdit" />
        </a-form-model-item>
        <a-form-model-item label="说明" prop="interfaceNote">
          <a-textarea
            v-model="modalFormState.interfaceNote"
            :disabled="!isEdit"
          />
        </a-form-model-item>
        <a-form-model-item label="标签" prop="Tag">
          <a-list itemLayout="horizontal" :bordered="true">
            <a-list-item>
              <div class="module-name-box">
                模块<a-divider type="vertical" />
              </div>
              <a-checkbox-group
                v-model="modalFormState.interfaceOfModule"
                :disabled="!isEdit"
              >
                <a-checkbox
                  v-for="item in modules.moduleList"
                  :key="item.moduleId"
                  :value="item.moduleId"
                  >{{ item.moduleName }}</a-checkbox
                >
              </a-checkbox-group>
            </a-list-item>
            <a-list-item>
              <div class="module-name-box">
                服务<a-divider type="vertical" />
              </div>
              <a-checkbox-group
                v-model="modalFormState.interfaceOfService"
                :disabled="!isEdit"
              >
                <a-checkbox
                  v-for="item in modules.serviceList"
                  :key="item.moduleId"
                  :value="item.moduleId"
                  >{{ item.moduleName }}</a-checkbox
                >
              </a-checkbox-group>
            </a-list-item>
            <a-list-item>
              <div class="module-name-box">
                页面<a-divider type="vertical" />
              </div>
              <a-checkbox-group
                v-model="modalFormState.interfaceOfPage"
                :disabled="!isEdit"
              >
                <a-checkbox
                  v-for="item in modules.pageList"
                  :key="item.moduleId"
                  :value="item.moduleId"
                  >{{ item.moduleName }}</a-checkbox
                >
              </a-checkbox-group>
            </a-list-item>
            <a-list-item>
              <div class="module-name-box">
                客户端<a-divider type="vertical" />
              </div>
              <a-checkbox-group
                v-model="modalFormState.interfaceOfClient"
                :disabled="!isEdit"
              >
                <a-checkbox
                  v-for="item in modules.clientList"
                  :key="item.moduleId"
                  :value="item.moduleId"
                  >{{ item.moduleName }}</a-checkbox
                >
              </a-checkbox-group>
            </a-list-item>
            <a-list-item>
              <div class="module-name-box">
                其他<a-divider type="vertical" />
              </div>
              <a-checkbox-group
                v-model="modalFormState.interfaceOfOthers"
                :disabled="!isEdit"
              >
                <a-checkbox
                  v-for="item in modules.otherList"
                  :key="item.moduleId"
                  :value="item.moduleId"
                  >{{ item.moduleName }}</a-checkbox
                >
              </a-checkbox-group>
            </a-list-item>
          </a-list>
        </a-form-model-item>
        <a-form-model-item label="请求字段" prop="input">
          <a-select
            style="width: calc(100% - 100px)"
            mode="multiple"
            v-model="modalFormState.input"
            :disabled="!isEdit"
            @deselect="deselect(true)"
            :open="false"
          >
          </a-select>
          <a-button
            style="width: 80px; margin-left: 20px"
            type="primary"
            @click="showParamModal(true)"
          >
            编辑
          </a-button>
        </a-form-model-item>
        <a-form-model-item label="返回字段" prop="output">
          <a-select
            style="width: calc(100% - 100px)"
            mode="multiple"
            v-model="modalFormState.output"
            :disabled="!isEdit"
            @deselect="deselect(false)"
          >
          </a-select>
          <a-button
            style="width: 80px; margin-left: 20px"
            type="primary"
            @click="showParamModal(false)"
          >
            编辑
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 选字段 -->
    <a-modal
      :width="1200"
      :visible="paramModalVisible"
      :title="(isInput ? '请求' : '返回') + '字段'"
      ok-text="确认"
      cancel-text="取消"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="selectParam"
      @cancel="
        () => {
          paramModalVisible = false;
        }
      "
    >
      <div style="display: flex; flex-direction: column">
        <a-card title="字段来源" size="small" style="width: 100%">
          <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
            <a-row>
              <a-col :span="6">
                <a-form-item label="mysql" name="mysql">
                  <a-select
                    v-model="paramOrigin.tableName"
                    @change="selectMysql"
                  >
                    <a-select-option
                      v-for="item in mysqlTableList"
                      :key="item.tableName"
                      >{{ item.tableName }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="redis" name="redis">
                  <a-select v-model="paramOrigin.tableId" @change="selectRedis">
                    <a-select-option
                      v-for="item in redisTableList"
                      :key="item.tableId"
                      >{{ item.tableName }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="模块" name="paramOfModule">
                  <a-select
                    v-model="paramOrigin.paramOfModule"
                    @change="selectModule"
                  >
                    <a-select-option
                      v-for="item in modules.moduleList"
                      :key="item.id"
                      :value="item.moduleId"
                    >
                      {{ item.moduleName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="页面" name="paramOfPage">
                  <a-select
                    v-model="paramOrigin.paramOfPage"
                    @change="selectPage('paramOfPage')"
                  >
                    <a-select-option
                      v-for="item in modules.pageList"
                      :key="item.id"
                      :value="item.moduleId"
                    >
                      {{ item.moduleName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
        <a-card
          title="选择字段"
          size="small"
          style="width: 100%; min-height: 550px"
        >
          <a-table
            v-if="paramOrigin.tableName"
            size="small"
            :bordered="true"
            :loading="colTableLoading"
            rowKey="colName"
            :columns="paramColumns.mysql"
            :dataSource="paramDataSource"
            :pagination="true"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
          ></a-table>
          <a-table
            v-else-if="paramOrigin.tableId"
            size="small"
            :bordered="true"
            :loading="colTableLoading"
            rowKey="colName"
            :columns="paramColumns.other"
            :dataSource="paramDataSource"
            :pagination="true"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
          ></a-table>
          <a-table
            v-else-if="paramOrigin.paramOfModule"
            size="small"
            :bordered="true"
            :loading="colTableLoading"
            rowKey="colName"
            :columns="paramColumns.other"
            :dataSource="paramDataSource"
            :pagination="true"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
          ></a-table>
          <a-table
            v-else-if="paramOrigin.paramOfPage"
            size="small"
            :bordered="true"
            :loading="colTableLoading"
            rowKey="colName"
            :columns="paramColumns.other"
            :dataSource="paramDataSource"
            :pagination="true"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
          ></a-table>
        </a-card>
      </div>
    </a-modal>
  </div>
</template>

<script>
import PagingQueryPage from "@/components/PagingQueryPage";
import { Modal, notification } from "ant-design-vue";
import { interfaceAdd, interfaceUpdate, interfaceDel } from "@/apis/interface";
import { moduleList } from "@/apis/module";
import { getModuleName } from "@/utils/util";
import { /* mysqlTableList, */ mysqlColList } from "@/apis/mysql";
import { /* redisTableList, */ redisColList } from "@/apis/redis";
import { paramList } from "@/apis/param";
export default {
  components: { PagingQueryPage },
  data() {
    return {
      queryFormState: {
        interfaceUrl: null,
        interfaceOfModule: null,
        interfaceOfService: null,
        interfaceOfPage: null,
        interfaceOfClient: null,
        interfaceOfOthers: null,
      },
      requestOptions: {},
      columns: [
        {
          title: "序号",
          dataIndex: "",
          width: 80,
          align: "center",
          fixed: "left",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "ID",
          dataIndex: "interfaceId",
          width: 150,
          align: "center",
          customRender: (text, record) => {
            return (
              <a-button
                type="link"
                onClick={() => {
                  this.edit(record);
                  this.isEdit = false;
                }}
              >
                {text}
              </a-button>
            );
          },
        },
        {
          title: "接口路径",
          dataIndex: "interfaceUrl",
          width: 200,
          align: "center",
        },
        {
          title: "说明",
          dataIndex: "interfaceNote",
          width: 300,
          align: "center",
        },
        {
          title: "标签",
          dataIndex: "",
          align: "center",
          customRender: (record) => {
            const {
              interfaceOfModule,
              interfaceOfService,
              interfaceOfPage,
              interfaceOfClient,
              interfaceOfOthers,
            } = record;
            const interfaceOfModuleName = getModuleName(
              interfaceOfModule,
              this.modules.moduleList
            );
            const interfaceOfServiceName = getModuleName(
              interfaceOfService,
              this.modules.serviceList
            );
            const interfaceOfPageName = getModuleName(
              interfaceOfPage,
              this.modules.pageList
            );
            const interfaceOfClientName = getModuleName(
              interfaceOfClient,
              this.modules.clientList
            );
            const interfaceOfOthersName = getModuleName(
              interfaceOfOthers,
              this.modules.otherList
            );
            const text = `${interfaceOfModuleName}、${interfaceOfServiceName}、${interfaceOfPageName}、${interfaceOfClientName}、${interfaceOfOthersName}`;
            text.replaceAll("，", "、");
            const arr = text.split("、");
            const newArr = this.$_.remove(arr, (item) => {
              return item != "undefined" && item != "";
            });
            return newArr.length > 0 ? newArr.join("、") : "";
          },
        },
        {
          title: "SQL脚本",
          dataIndex: "",
          width: 100,
          align: "center",
          customRender: (record) => {
            return (
              <a-button
                type="link"
                onClick={() => {
                  this.editScript(record, "SQL");
                }}
              >
                编辑
              </a-button>
            );
          },
        },
        // {
        //   title: "REDIS脚本",
        //   dataIndex: "",
        //   width: 100,
        //   align: "center",
        //   customRender: (record) => {
        //     return (
        //       <a-button
        //         type="link"
        //         onClick={() => {
        //           this.editScript(record, "REDIS");
        //         }}
        //       >
        //         编辑
        //       </a-button>
        //     );
        //   },
        // },
        // {
        //   title: "JS代码",
        //   dataIndex: "",
        //   width: 100,
        //   align: "center",
        //   customRender: () => {
        //     return (
        //       <a-button
        //         type="link"
        //         onClick={() => {
        //           window.location.href =
        //             "http://mock.mtwang.cn:8080/?folder=/opt/diti-basicwork-node/src";
        //         }}
        //       >
        //         编辑
        //       </a-button>
        //     );
        //   },
        // },
        {
          title: "操作",
          dataIndex: "",
          width: 200,
          align: "center",
          fixed: "right",
          customRender: (record) => {
            return (
              <span class="action-group">
                <a-button
                  type="link"
                  onClick={() => {
                    this.edit(record);
                  }}
                >
                  编辑
                </a-button>
                <a-divider type="vertical" />
                <a-button
                  class="delBtn"
                  type="link"
                  onClick={() => {
                    this.del(record);
                  }}
                >
                  删除
                </a-button>
              </span>
            );
          },
        },
      ],
      pagination: {},
      modalVisible: false, // 弹窗状态
      isAdd: true, // 是否新增
      modalTitle: "接口", // 弹窗标题
      modalRules: {
        interfaceUrl: [
          { required: true, message: "路径必填", trigger: "blur" },
        ],
      }, // 弹窗校验规则
      modalFormState: {
        interfaceUrl: null,
        interfaceNote: null,
        interfaceOfModule: null,
        interfaceOfService: null,
        interfaceOfPage: null,
        interfaceOfClient: null,
        interfaceOfOthers: null,
        input: null,
        output: null,
      }, // 弹窗值
      modules: {
        moduleList: [],
        serviceList: [],
        pageList: [],
        clientList: [],
        otherList: [],
      },
      // 字段弹窗
      paramDataSource: [],
      paramColumns: {
        mysql: [
          {
            title: "序号",
            dataIndex: "",
            width: 60,
            align: "center",
            customRender: (record, text, index) => {
              return index + 1;
            },
          },
          {
            title: "字段名称",
            dataIndex: "colName",
            width: 200,
            align: "center",
          },
          {
            title: "字段说明",
            dataIndex: "colTip",
            width: 200,
            align: "center",
          },
          {
            title: "字段类型",
            dataIndex: "colType",
            width: 200,
            align: "center",
          },
        ],
        other: [
          {
            title: "序号",
            dataIndex: "",
            width: 60,
            align: "center",
            customRender: (record, text, index) => {
              return index + 1;
            },
          },
          {
            title: "字段名称",
            dataIndex: "colName",
            width: 200,
            align: "center",
          },
          {
            title: "字段说明",
            dataIndex: "colTip",
            width: 200,
            align: "center",
          },
        ],
      },
      paramModalVisible: false, // 是否显示字段弹窗
      isEdit: true, // 是否编辑
      colTableLoading: false, // table状态
      isInput: true, // 输入或者输出
      selectedRowKeys: [], // 选中的key
      selectedRows: [], // 选中的row
      // 字段来源
      mysqlTableList: [],
      redisTableList: [],
      paramOrigin: {
        tableName: null,
        tableId: null,
        paramOfModule: null,
        paramOfPage: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      moduleList({ moduleType: "0" }).then((res) => {
        const {
          data: { resList },
          code,
        } = res.data;
        if (code === "200") {
          if (resList.length > 0) {
            this.modules.moduleList = resList.filter(
              (r) => r.moduleType === "1"
            );
            this.modules.serviceList = resList.filter(
              (r) => r.moduleType === "2"
            );
            this.modules.pageList = resList.filter((r) => r.moduleType === "3");
            this.modules.clientList = resList.filter(
              (r) => r.moduleType === "4"
            );
            this.modules.otherList = resList.filter(
              (r) => r.moduleType === "5"
            );
          }
        }
      });
      // mysqlTableList().then((res) => {
      //   const {
      //     data: { resList },
      //     code,
      //   } = res.data;
      //   if (code === "200") {
      //     if (!this.$_.isEmpty(resList)) {
      //       this.mysqlTableList = resList;
      //       // this.paramOrigin.tableName = resList[0]?.tableName;
      //     }
      //   }
      // });
      // redisTableList().then((res) => {
      //   const {
      //     data: { resList },
      //     code,
      //   } = res.data;
      //   if (code === "200") {
      //     if (!this.$_.isEmpty(resList)) {
      //       this.redisTableList = resList;
      //       // this.paramOrigin.tableId = resList[0]?.tableId;
      //     }
      //   }
      // });
    },
    add() {
      this.modalVisible = true;
      this.isAdd = true;
      this.isEdit = true;
      if (this.modalFormState.interfaceId)
        delete this.modalFormState.interfaceId;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = null;
        }
      }
      delete this.modalFormState.input;
      delete this.modalFormState.inputArr;
      delete this.modalFormState.output;
      delete this.modalFormState.inputArr;
    },
    edit(record) {
      this.modalVisible = true;
      this.isAdd = false;
      this.isEdit = true;
      this.modalFormState.interfaceId = record.interfaceId;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = record[key];
        }
      }
      this.modalFormState.interfaceOfModule =
        typeof record.interfaceOfModule === "string"
          ? record.interfaceOfModule.split(",")
          : record.interfaceOfModule;
      this.modalFormState.interfaceOfService =
        typeof record.interfaceOfService === "string"
          ? record.interfaceOfService.split(",")
          : record.interfaceOfService;
      this.modalFormState.interfaceOfPage =
        typeof record.interfaceOfPage === "string"
          ? record.interfaceOfPage.split(",")
          : record.interfaceOfPage;
      this.modalFormState.interfaceOfClient =
        typeof record.interfaceOfClient === "string"
          ? record.interfaceOfClient.split(",")
          : record.interfaceOfClient;
      this.modalFormState.interfaceOfOthers =
        typeof record.interfaceOfOthers === "string"
          ? record.interfaceOfOthers.split(",")
          : record.interfaceOfOthers;
      // 把record存的key：value转换成数组，显示在select框
      if (record.input) {
        const obj = JSON.parse(record.input);
        const keyArr = [],
          rowArr = [];
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            keyArr.push(key);
            rowArr.push({ colName: key, colTip: obj[key] });
          }
        }
        this.modalFormState.input = keyArr;
        this.modalFormState.inputArr = rowArr;
      }
      if (record.output) {
        const obj = JSON.parse(record.output);
        const keyArr = [],
          rowArr = [];
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            keyArr.push(key);
            rowArr.push({ colName: key, colTip: obj[key] });
          }
        }
        this.modalFormState.output = keyArr;
        this.modalFormState.outputArr = rowArr;
      }
    },
    submit() {
      this.$refs["modalForm"].validate((valid) => {
        if (valid) {
          const obj = {};
          for (const key in this.modalFormState) {
            if (Object.hasOwnProperty.call(this.modalFormState, key)) {
              obj[key] = this.modalFormState[key];
              // 去除空格
              if (key == "interfaceUrl") {
                obj.interfaceUrl = this.modalFormState.interfaceUrl.trim();
              }
              if (this.modalFormState.interfaceOfModule) {
                obj.interfaceOfModule =
                  typeof this.modalFormState.interfaceOfModule === "object"
                    ? this.modalFormState.interfaceOfModule.join(",")
                    : this.modalFormState.interfaceOfModule;
              }
              if (this.modalFormState.interfaceOfService) {
                obj.interfaceOfService =
                  typeof this.modalFormState.interfaceOfService === "object"
                    ? this.modalFormState.interfaceOfService.join(",")
                    : this.modalFormState.interfaceOfService;
              }
              if (this.modalFormState.interfaceOfPage) {
                obj.interfaceOfPage =
                  typeof this.modalFormState.interfaceOfPage === "object"
                    ? this.modalFormState.interfaceOfPage.join(",")
                    : this.modalFormState.interfaceOfPage;
              }
              if (this.modalFormState.interfaceOfClient) {
                obj.interfaceOfClient =
                  typeof this.modalFormState.interfaceOfClient === "object"
                    ? this.modalFormState.interfaceOfClient.join(",")
                    : this.modalFormState.interfaceOfClient;
              }
              if (this.modalFormState.interfaceOfOthers) {
                obj.interfaceOfOthers =
                  typeof this.modalFormState.interfaceOfOthers === "object"
                    ? this.modalFormState.interfaceOfOthers.join(",")
                    : this.modalFormState.interfaceOfOthers;
              }
              if (this.modalFormState.input) {
                const input = {};
                const arr = this.modalFormState.inputArr;
                arr.forEach((element) => {
                  input[element.colName] = element.colTip;
                });
                obj.input = JSON.stringify(input);
                delete obj.inputArr;
              }
              if (this.modalFormState.outputArr) {
                const output = {};
                const arr = this.modalFormState.outputArr;
                arr.forEach((element) => {
                  output[element.colName] = element.colTip;
                });
                obj.output = JSON.stringify(output);
                delete obj.outputArr;
              }
            }
          }
          if (this.isAdd) {
            interfaceAdd({
              ...this.requestOptions,
              ...obj,
            }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                this.modalVisible = false;
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "新增成功",
                });
              }
            });
          } else {
            interfaceUpdate({ ...obj }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                this.modalVisible = false;
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "编辑成功",
                });
              }
            });
          }
        }
      });
    },
    del(record) {
      Modal.confirm({
        title: `确定要删除该${this.modalTitle}吗？`,
        content: "注意：此操作无法撤销！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          interfaceDel({ interfaceId: record.interfaceId }).then((res) => {
            const { code } = res.data;
            if (code === "200") {
              this.$refs["PagingQueryPage"].query();
              notification.success({
                message: "删除成功",
              });
            }
          });
        },
      });
    },
    showParamModal(type) {
      // 展示字段弹窗表格，编辑时带出之前选中的字段
      this.isInput = type;
      this.paramModalVisible = true;
      if (type) {
        this.selectedRowKeys = Array.isArray(this.modalFormState.input)
          ? this.modalFormState.input
          : [];
        this.selectedRows = Array.isArray(this.modalFormState.inputArr)
          ? this.modalFormState.inputArr
          : [];
      } else {
        this.selectedRowKeys = Array.isArray(this.modalFormState.output)
          ? this.modalFormState.output
          : [];
        this.selectedRows = Array.isArray(this.modalFormState.outputArr)
          ? this.modalFormState.outputArr
          : [];
      }
    },
    // 删除选中的字段回调
    deselect(type) {
      if (type) {
        this.selectedRowKeys = this.modalFormState.input;
        this.selectedRows = this.modalFormState.inputArr;
      } else {
        this.selectedRowKeys = this.modalFormState.output;
        this.selectedRows = this.modalFormState.outputArr;
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      // 选择表格字段
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    selectParam() {
      // 选中后的回调，回传
      if (this.isInput) {
        this.modalFormState.input = this.selectedRowKeys;
        this.modalFormState.inputArr = this.selectedRows;
      } else {
        this.modalFormState.output = this.selectedRowKeys;
        this.modalFormState.outputArr = this.selectedRows;
      }
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.paramModalVisible = false;
    },
    // 修改字段来源条件
    selectMysql(value) {
      for (const key in this.paramOrigin) {
        if (Object.hasOwnProperty.call(this.paramOrigin, key)) {
          this.paramOrigin[key] = null;
        }
      }
      this.paramOrigin["tableName"] = value;
      this.colTableLoading = true;
      mysqlColList({ tableName: value }).then((res) => {
        const {
          data: { resList },
          code,
        } = res.data;
        if (code === "200") {
          this.paramDataSource = resList;
          this.colTableLoading = false;
        }
      });
    },
    // 过滤redis拿到的结果
    redisFilter(resList) {
      resList.forEach((element) => {
        for (const key in element) {
          if (Object.hasOwnProperty.call(element, key)) {
            element.colName = key;
            element.colTip = element[key];
          }
        }
      });
      return resList;
    },
    selectRedis(value) {
      for (const key in this.paramOrigin) {
        if (Object.hasOwnProperty.call(this.paramOrigin, key)) {
          this.paramOrigin[key] = null;
        }
      }
      this.paramOrigin["tableId"] = value;
      this.colTableLoading = true;
      redisColList({ tableId: value }).then((res) => {
        const {
          data: { resList },
          code,
        } = res.data;
        if (code === "200") {
          this.paramDataSource = this.redisFilter(resList);
          this.colTableLoading = false;
        }
      });
    },
    selectModule(value) {
      for (const key in this.paramOrigin) {
        if (Object.hasOwnProperty.call(this.paramOrigin, key)) {
          this.paramOrigin[key] = null;
        }
      }
      this.paramOrigin["paramOfModule"] = value;
      this.colTableLoading = true;
      paramList({ paramOfModule: value }).then((res) => {
        const {
          data: { resList },
          code,
        } = res.data;
        if (code === "200") {
          const arr = [];
          resList.map((item, index) => {
            arr[index] = { colName: item.paramName, colTip: item.paramNote };
          });
          this.paramDataSource = arr;
          this.colTableLoading = false;
        }
      });
    },
    selectPage(value) {
      for (const key in this.paramOrigin) {
        if (Object.hasOwnProperty.call(this.paramOrigin, key)) {
          this.paramOrigin[key] = null;
        }
      }
      this.paramOrigin["paramOfPage"] = value;
      this.colTableLoading = true;
      paramList({ paramOfPage: value }).then((res) => {
        const {
          data: { resList },
          code,
        } = res.data;
        if (code === "200") {
          const arr = [];
          resList.map((item, index) => {
            arr[index] = { colName: item.paramName, colTip: item.paramNote };
          });
          this.paramDataSource = arr;
          this.colTableLoading = false;
        }
      });
    },
    // 编辑脚本
    editScript(record, type) {
      this.$router.push({
        path: "/config/interface/script",
        query: { type, id: record.interfaceId },
      });
    },
    // 测试脚本
    test() {
      // this.$router.push({
      //   path: "/interface/msg/test",
      //   query: { interfaceId: record.interfaceId },
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.ant-list-item {
  height: 40px;
  line-height: 40px;
  display: flex;
  .module-name-box {
    width: 70px;
    height: 40px;
    .ant-divider {
      width: 1px;
      height: 40px;
      background-color: #f0f0f0;
      float: right;
    }
  }
  .ant-checkbox-group {
    flex: 1 1 0%;
  }
}
</style>
