import request from "@/utils/request";

function redisTableList(data) {
  return request({
    url: "/show/redis/table/list",
    method: "post",
    data: data,
  });
}

function redisTableAdd(data) {
  return request({
    url: "/show/redis/table/add",
    method: "post",
    data: data,
  });
}

function redisTableDel(data) {
  return request({
    url: "/show/redis/table/del",
    method: "post",
    data: data,
  });
}

function redisTableUpdate(data) {
  return request({
    url: "/show/redis/table/update",
    method: "post",
    data: data,
  });
}

function redisColList(data) {
  return request({
    url: "/show/redis/col/list",
    method: "post",
    data: data,
  });
}

function redisColAdd(data) {
  return request({
    url: "/show/redis/col/add",
    method: "post",
    data: data,
  });
}

function redisColDel(data) {
  return request({
    url: "/show/redis/col/del",
    method: "post",
    data: data,
  });
}

function redisColRetip(data) {
  return request({
    url: "/show/redis/col/retip",
    method: "post",
    data: data,
  });
}

export {
  redisTableList,
  redisTableAdd,
  redisTableDel,
  redisTableUpdate,
  redisColList,
  redisColAdd,
  redisColDel,
  redisColRetip,
};
